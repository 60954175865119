.carousel-container {
  width: 100%;
  max-width: 382px;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 1rem;
  max-height: 360px;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 3rem 1rem 3rem;
}

.tabs button {
  flex: 1;
  padding: 1rem;
  background: none;
  //font-size: 16px;
  //font-weight: 500;
  color: #666;
  cursor: pointer;
  transition: color 0.3s;
  border-radius: 0;
  border-bottom: 2px solid #E0E0E0;
  font-family: var(--font-family--body), sans-serif;

  &.active {
    color: var(--color-black);
    border-bottom: 2px solid var(--color-black);
  }
}

.image-container {
  position: relative;
  width: 100%;
  padding-top: 75%; // 4:3 aspect ratio
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: 2px solid #686868;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-size: 18px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;

  &:active {
    transform: translateY(-50%);
  }
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}
.no-images-message {
  text-align: center;
  padding: 20px;
  font-style: italic;
  color: #666;
}
