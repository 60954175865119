.editable-heading-icon {
  margin-left: 1rem;
}
.editable-heading {
  display: flex;
  align-items: start;
  flex-direction: column;

  h1 {
    &:focus {
      background-color: #f0f0f0;
      transition: all 0.3s ease;
      outline: 2px solid var(--color-black);
      outline-offset: 2px;
    }
    &:focus,
    &:hover {
      cursor: text;
    }
  }

  svg {
    width: 24px;
    height: 24px;
    color: var(--color-black);
    cursor: pointer;
  }
}
